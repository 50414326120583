.Contact{
    width: auto;
    height: auto;
    background-color: rgba(0, 0, 30);
    z-index: 2; 
    color: white;
    padding: 0px 12.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.contactDescription{
    margin: 4rem 0px;
    font-size: clamp(16px, 1.6vw, 1.4rem);
}

.contactBtn2{
    border: 2px solid white;
    color: white;
    width: 40vw;
    height: 10vw;
    max-width: 10rem;
    max-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6vw 1vw;
}

.copyright{
    font-size: clamp(12px, 1.2vw, 1.4rem);
    margin: 2rem 0px;
}

a:link {
    text-decoration: none;
    color: white;
    font-size: clamp(16px, 1.6vw, 1.1rem);
  }
  