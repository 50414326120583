.ServicePages{
    overflow-x: hidden;
    overflow-y: auto;
    padding: 6vw 12.5vw 2vw 12.5vw;
    font-size: clamp(16px, 4vw, 1.2rem);
}

.serviceMark{
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    font-size: clamp(24px, 4vw, 2.2rem);
    font-weight: bold;
    padding-left: 1rem;
    border-left: 5px solid rgba(0, 0, 30); 
    height: 4vw;
    min-height: 2rem;
    margin-bottom: clamp(34px, 4vw, 4vw);
}

