.Service{
    position: relative;
    width: auto;
    background-color:  rgba(0, 0, 30, 0.9); 
    z-index: 2; 
    color: #d4fff8;
    padding: 6vw 12.5vw;
}

.serviceMark2{
    display: flex;
    align-items: center;
    text-align: left;
    font-size: clamp(24px, 4vw, 2.2rem);
    font-weight: bold;
    padding-left: 1rem;
    border-left: 5px solid #d4fff8;
    height: 4vw;
    min-height: 2rem;
    margin-bottom: clamp(34px, 4vw, 4vw);
}

.ServiceGrid{
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 28rem));
    grid-gap: 2vw;
    align-items: center;
    justify-content: center;
}

.grid{
    position: relative;
    display: flex;
    height:  clamp(8rem, 9vw, 9vw);
    width: auto;
    background-color:  rgba(0, 0, 30);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.gridImage{
    position: relative;
    width: 100%;
    height: clamp(8rem, 9vw, 9vw);
    object-fit: cover;
    opacity: 0.6;
    cursor: pointer;
}

.serviceTitle{
    position: absolute;
    color: white;
    cursor: pointer;
    font-size: clamp(16px, 4vw, 1.6rem);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.serviceTitle:hover{
    background-color: rgba(0, 0, 30, 0.4);
    height: 100%;
    width: 100%;
}

.ServiceDescriptions{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
}

.descriptionTitle{
    font-size: clamp(20px, 4vw, 2.2rem);
    text-align: center;
    font-weight: bold;
}

.descriptionHeader{
    font-size: clamp(20px, 4vw, 1.8rem);
    text-align: left;
    font-weight: bold;
    color: #d4fff8;
}

.descriptionContent{
    font-size: clamp(16px, 4vw, 1.2rem);
    display: flex;
    flex-direction: column;
    margin: 2rem 0rem;

}
.cleaningTitles{
    float: left;
}

.cleaningDescriptions{
    float: left;
}