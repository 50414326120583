.App {
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
}


.HomePic{
  z-index: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}