
/* Testimonials*/
.testimonials{
    background-color: white;
    width: auto;
    position: relative;
    z-index: 2;
    margin: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 0px;
    padding-left: clamp(10px, 8vw, 20rem);
    padding-right: clamp(10px, 8vw, 20rem);
    padding-top: clamp(40px, 6vw, 20rem);
    padding-bottom:  clamp(40px, 6vw, 20rem);
  }


.testimonial{
    padding: 5vw;
}

.quote{
    font-size: clamp(16px, 4vw, 1.4rem);
    
    text-align: left;
  }

.company{
    font-size: clamp(20px, 4vw, 1.8rem);
    font-weight: bold;
    font-family: serif;
    text-align: right;
    margin-top: 1rem;
}