.About{
    position: relative;
    width: auto;
    height: auto;
    background-color: white;
    z-index: 2; 
    color: rgba(0, 0, 30); ;
    padding: 6vw 12.5vw;
    
}

.aboutMark{
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    font-size: clamp(24px, 4vw, 2.2rem);
    font-weight: bold;
    padding-left: 1rem;
    border-left: 5px solid rgba(0, 0, 30); 
    height: 4vw;
    min-height: 2rem;
    margin-bottom: clamp(34px, 4vw, 4vw);
}

.info1Grid{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns:  repeat( auto-fit, minmax(200px, 1fr) );
    grid-gap: 4vw;
}

.info1{
    width: 100%;
}

.cleaningPic{
    width: 100%;
    max-width: 36rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.info2{
    margin: 8vw 0;
}

.introduction{
    background-color: #d4fff8;
    padding: 4vw;
    display: grid;
    grid-template-columns:  repeat( auto-fit, minmax(200px, 1fr) );
    grid-gap: 4vw;
    align-items: center;
    justify-content: center;
}

.flowerPic{
    width:100%;
}

.info3{
    text-align: center;
}