.Information{
    position: relative;
    width: auto;
    height: auto;
    background-color: white;
    z-index: 2; 
    color: rgba(0, 0, 30); ;
    padding: 6vw 12.5vw;
    font-size: 1.4rem;
}

.informationMark{
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    font-size: clamp(24px, 4vw, 2.2rem);
    font-weight: bold;
    padding-left: 1rem;
    border-left: 5px solid rgba(0, 0, 30); 
    height: 4vw;
    min-height: 2rem;
    margin-bottom: clamp(34px, 4vw, 4vw);
}

.companyDescription{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.topicGroup{
    margin-bottom: 3vw;
}

.topic{
    font-size: clamp(16px, 4vw, 1.4rem);
    text-align: center;
}

.description{
    font-size: clamp(16px, 4vw, 1.4rem);
}
