html{
  font-size: 76%;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'PT Serif', serif;
  font-family: "游明朝体", "Yu Mincho", YuMincho;
  color: #00002A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
